var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-favorites-products"},[(_vm.viewType === 'grid')?_c('div',{staticClass:"grid"},[_c('VfRow',_vm._l((_vm.tiles),function(item,index){return _c('VfColumn',{key:'grid-favorite-item-' + item.itemId + '-' + index,attrs:{"small":6,"medium":3,"large":3}},[(!item.isNotification)?_c('div',{staticClass:"vf-favorite-item-card"},[_c('div',[_c('UiLink',{staticClass:"vf-favorite-item-card__name item-link",attrs:{"to":item.pdpUrl}},[_c('div',{staticClass:"image-container"},[_c('VfPicture',{attrs:{"src":item.productImageUrl,"alt":item.name,"width":"100%","height":"auto"}}),_vm._v(" "),_c('VfButton',{staticClass:"add-to-cart",on:{"click":function($event){$event.preventDefault();return _vm.triggerAddToCart(item.id, index)}}},[_vm._v("\n                  "+_vm._s(_vm.addToCartLabel)+"\n                ")])],1)]),_vm._v(" "),_c('UiLink',{staticClass:"vf-favorite-item-card__name item-link",attrs:{"to":item.pdpUrl}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")]),_vm._v(" "),_c('VfPrice',{staticClass:"vf-favorite-item-card__price",attrs:{"small":"","regular":_vm.$root.$formatPrice(item.price.original, item.price.currency),"special":item.price.original !== item.price.current
                  ? _vm.$root.$formatPrice(
                      item.price.current,
                      item.price.currency
                    )
                  : ''}})],1),_vm._v(" "),_c('div',[_c('VfButton',{staticClass:"add-to-cart-mobile",on:{"click":function($event){return _vm.triggerAddToCart(item.id, index)}}},[_vm._v("\n              "+_vm._s(_vm.addToCartLabel)+"\n            ")]),_vm._v(" "),_c('UiLink',{staticClass:"vf-link vf-favorite-item-card__remove",attrs:{"tag":"a"},on:{"click":function($event){$event.preventDefault();return _vm.triggerRemoveItem(item, index)}}},[_vm._v("\n              "+_vm._s(_vm.translations.removeLabel)+"\n            ")])],1)]):_vm._e(),_vm._v(" "),(item.isNotification)?_c('div',{staticClass:"vf-favorite-item-notification-container"},[_c('VfNotification',{staticClass:"vf-favorite-item-notification-item",attrs:{"visible":true,"type":'success',"show-close-button":false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.itemNotificationLabel)+"\n            ")]},proxy:true}],null,true)})],1):_vm._e()])}),1)],1):_vm._e(),_vm._v(" "),(_vm.viewType === 'list')?_c('VfContainer',{staticClass:"list vf-container--border-top"},_vm._l((_vm.tiles),function(item,index){return _c('VfRow',{key:'row-favorite-item-' + item.itemId + '-' + index,staticClass:"vf-favorite-item__row"},[_c('VfContainer',{staticClass:"vf-container--full-width"},[(!item.isNotification)?_c('VfRow',{staticClass:"vf-favorite-item__row child"},[_c('VfColumn',{attrs:{"small":5,"medium":4,"large":4,"content-position":{
              small: 'top_left',
              medium: 'middle_left',
              large: 'middle_left',
            }}},[_c('UiLink',{attrs:{"to":item.pdpUrl}},[_c('VfPicture',{attrs:{"src":item.productImageUrl,"alt":item.name,"width":129,"height":109}})],1)],1),_vm._v(" "),_c('VfColumn',{staticClass:"product-info-container",attrs:{"small":7,"medium":5,"large":5,"content-position":{
              small: 'top_left',
              medium: 'middle_left',
              large: 'middle_left',
            }}},[_c('UiLink',{staticClass:"item-link",attrs:{"to":item.pdpUrl}},[_c('span',[_c('VfText',{attrs:{"font-sizes":['sm']}},[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")]),_vm._v(" "),_c('VfPrice',{staticClass:"row-price",attrs:{"small":"","regular":_vm.$root.$formatPrice(
                      item.price.original,
                      item.price.currency
                    ),"special":item.price.original !== item.price.current
                      ? _vm.$root.$formatPrice(
                          item.price.current,
                          item.price.currency
                        )
                      : ''}})],1)])],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":12,"medium":3,"large":3,"content-position":{
              small: 'bottom_center',
              medium: 'bottom_center',
              large: 'bottom_center',
            }}},[_c('VfButton',{staticClass:"vf-favorite-item__button",on:{"click":function($event){return _vm.triggerAddToCart(item.id, index)}}},[_vm._v("\n              "+_vm._s(_vm.translations.addToCartLabel)+"\n            ")]),_vm._v(" "),_c('UiLink',{staticClass:"vf-favorite-item__remove vf-link",attrs:{"tag":"a"},on:{"click":function($event){$event.preventDefault();return _vm.triggerRemoveItem(item, index)}}},[_vm._v("\n              "+_vm._s(_vm.translations.removeLabel)+"\n            ")])],1)],1):_vm._e(),_vm._v(" "),(item.isNotification)?_c('VfRow',{staticClass:"vf-favorite-item__row child"},[_c('VfColumn',{attrs:{"small":12,"medium":12,"large":12}},[_c('VfNotification',{staticClass:"vf-favorite-item-notification-item",attrs:{"visible":true,"type":'success',"show-close-button":false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v("\n                "+_vm._s(_vm.itemNotificationLabel)+"\n              ")]},proxy:true}],null,true)})],1)],1):_vm._e()],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }