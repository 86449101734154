//
//
//
//
//
//
//
//

import {
  computed,
  watch,
  onUnmounted,
  onMounted,
} from '@nuxtjs/composition-api';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import {
  getRestoredSearchSettings,
  useSearch,
  useGtm,
  useFavorites,
  useSignInToStore,
} from '@vf/composables';
import { getFinalPdoSearch } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';

export default {
  layout: 'cms/cmsDefault',
  setup() {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    // TODO: Cleanup in GLOBAL15-63799
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const {
      getSearchResults,
      setActivePage,
      setQueryString,
      getSearchSetting,
      pagination,
      queryString,
      autoCorrectQuery,
      isRedirected,
      pdoSearch,
      clearPdoSearch,
      resetSearchResults,
    } = useSearch(root);
    const { dispatchEvent } = useGtm(root);
    const { getFavorites } = useFavorites(root);
    const { employeeConnected } = useSignInToStore(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);

    const queryTerm = computed(() => root.$route.query.q);

    const callFavoritesOnSRPAndPLP = root.$getCustomConfiguration(
      'callFavoritesOnSRPAndPLP'
    );

    // Immediately calling getSearchResults causes a race condition
    // with the apiDateFetchMethod in the CmsPage component
    watch(queryTerm, async () => {
      setQueryString(queryTerm.value);
      await getSearchResults();
      // TODO: Cleanup in GLOBAL15-63799
      // When the clean up for isVansPlpRedesignEnabled is done here we can get rid of this if statement completely
      // as favorites CTA won't be shown on this page anymore.
      // Moreover, we can get rid of the callFavoritesOnSRPAndPLP variable as well.
      if (
        callFavoritesOnSRPAndPLP &&
        !employeeConnected.value &&
        !isVansPlpRedesignEnabled
      ) {
        getFavorites(root.$route.fullPath);
      }
      dispatchEvent({
        eventName: 'loadPageData',
        overrideAttributes: {
          pageTypeName: pageTypeName.value || PageTypeName.SEARCH,
          categoryID: root.$route.params.id || undefined,
          ...getFinalPdoSearch(
            pdoSearch.value,
            pageTypeName.value || PageTypeName.SEARCH,
            queryString.value,
            autoCorrectQuery.value,
            isRedirected.value,
            pagination.value.total
          ),
        },
      });
      clearPdoSearch();
      dispatchEvent({ eventName: 'loadUserData' });
      const pageUrl = root.$route.fullPath;
      const routeName = root.$route.name;
      dispatchEvent({
        eventName: 'virtualPageView',
        overrideAttributes: {
          pageUrl,
          routeName,
        },
      });
    });

    // TODO: Cleanup in GLOBAL15-63799
    // When the clean up for isVansPlpRedesignEnabled is done here we can get rid of this if statement completely
    // as favorites CTA won't be shown on this page anymore.
    // Moreover, we can get rid of the callFavoritesOnSRPAndPLP variable as well.
    onMounted(() => {
      if (
        callFavoritesOnSRPAndPLP &&
        !employeeConnected.value &&
        !isVansPlpRedesignEnabled
      ) {
        getFavorites(root.$route.fullPath);
      }
    });

    onUnmounted(() => {
      resetSearchResults();
    });
    const apiDataFetchMethod = async () => {
      const searchSettingsSrp = await getSearchSetting(
        getRestoredSearchSettings()
      );
      if (isClient && !window.productVueClientRender) {
        // this check remove double call on client if is done by Server Side
        return;
      }
      setActivePage(searchSettingsSrp.page);
      setQueryString(queryTerm.value);
      await getSearchResults(searchSettingsSrp, {
        useCache: !root.$themeConfig.productsGrid?.loadPrevious
          .isLoadPreviousEnabled,
      });
    };

    return {
      apiDataFetchMethod,
      isVansPlpRedesignEnabled,
    };
  },
  asyncData() {
    if (isClient) {
      // variable to understand if page is render from SSR or CSR
      window.productVueClientRender = true;
    }
  },
};
