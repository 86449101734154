



































































































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  provide,
  ref,
  Ref,
  watch,
} from '@nuxtjs/composition-api';
import { CheckoutContext, Context } from '@vf/api-contract';

import {
  useCart,
  useMonetate,
  useRouting,
  useSaveForLater,
  useSignInToStore,
  useTrackPage,
} from '@vf/composables';
import { useConsumer } from '@vf/composables/src/useAuthentication/composables/useConsumer';

import { useUserData } from '../../components/cms/cmsUtils';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { isClient, isPickupOrSts } from '@vf/shared/src/utils/helpers';

import Placement from '@/components/smart/grid/Placement.vue';
import CartTopSummary from '@/components/static/cart/CartTopSummary.vue';
import EmptyCart from '@/components/static/cart/EmptyCart.vue';
import PromoCodeSidebar from '@/components/static/PromoCodeSidebar.vue';
import OrderSummarySidebar from '@/components/static/OrderSummarySidebar.vue';
import CartCtaSidebar from '@/components/static/cart/CartCtaSidebar.vue';
import InformationalSidebar from '@/components/static/InformationalSidebar.vue';
import CartProducts from '@/components/static/cart/CartProducts.vue';
import DiscountNotification from '@/components/static/DiscountNotification.vue'; // TODO lazy load
import CartTopPromoNotifications from '@/components/static/cart/CartTopPromoNotifications.vue';

import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import useModal from '@/shared/useModal';

import { fetchPageContent } from '@vf/composables/src/useCms/dataFetcher/pages';
import { ComposableContext } from '@vf/composables/src/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import {
  InitializedApiClient,
  PageTypeName,
} from '@vf/composables/src/useCms/types';
import { extractDynamicSlots } from '@vf/composables/src/useCms/utils/extractDynamicSlots';

export default defineComponent({
  name: 'CartStatic',
  key() {
    return useFeatureFlagsStore().isCheckoutRedesignEnabled
      ? 'cart-page'
      : 'cms-page';
  },
  components: {
    Placement,
    CartTopSummary,
    DiscountNotification,
    EmptyCart,
    OrderSummarySidebar,
    CartCtaSidebar,
    InformationalSidebar,
    PromoCodeSidebar,
    CartProducts,
    OutOfStockProducts: () =>
      import('@/components/static/cart/OutOfStockProducts.vue'),
    SavedForLaterProducts: () =>
      import('@/components/static/cart/SavedForLaterProducts.vue'),
    CartTopPromoNotifications,
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    CartNotifications: () =>
      import('@/components/static/CartNotifications.vue'),
  },
  layout() {
    return useFeatureFlagsStore().isCheckoutRedesignEnabled
      ? 'defaultStatic'
      : 'cms/cmsDefault';
  },
  transition: 'fade',
  setup() {
    const {
      isBopisEnabled,
      isCheckoutRedesignEnabled,
      // TODO: isCoreRedesignEnabled - GLOBAL15-61059 remove after core redesign
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();
    const { root } = useRootInstance();
    const { employeeConnected } = useSignInToStore(root);
    const { consumerId, getSegments } = useConsumer(root);
    const {
      cartId: legacyCartId,
      loadCart,
      setMiniCart,
      keepCartAlive,
      updateCartForBopis,
      showExpiredSharedCartError,
    } = useCart(root);
    const { getSaveForLaterItems, savedForLaterProducts } = useSaveForLater(
      root
    );
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    // TODO: GLOBAL15-56318 Remove after checkout redesign
    provide('isCheckoutRedesignEnabled', isCheckoutRedesignEnabled);

    if (!isCheckoutRedesignEnabled) {
      // this block of logic is moved from `pages/index.vue`
      // (only parts related to cart page to preserve behavior
      // from fully CMS-driven approach)
      // TODO: GLOBAL15-56318 clean up after permanent switch to the new flow
      if (isClient) {
        if (!employeeConnected.value) {
          getSaveForLaterItems();
        }
        showExpiredSharedCartError();

        if (!legacyCartId.value) {
          loadCart({
            isBackgroundRequest: false,
            isTemporary: false,
            inventorySupplyCheck: true,
          });
        } else {
          if (isBopisEnabled) {
            updateCartForBopis();
          } else {
            keepCartAlive();
          }
        }
        setMiniCart(false);

        if (root.$config.areIpaWranxEnabled) {
          watch(
            consumerId,
            (currentValue, prevValue) => {
              if (
                loggedIn.value &&
                currentValue &&
                currentValue !== prevValue
              ) {
                getSegments();
              }
            },
            { immediate: true }
          );
        }
      }
      // full stop here as all the rest of the logic is
      // specific to statically-driven cart page
      return { isCheckoutRedesignEnabled };
    }

    const pageTypeName = PageTypeName.CART;
    const theme = root.$themeConfig.staticCart;
    const { showSpinner, hideSpinner } = useLoader();
    const contextKey = Context.PageContent;
    const context: ComposableContext = {
      instance: root,
      contextKey,
    };
    const { getPathWithoutLocalization } = useRouting(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);
    const cmsApiClient: Ref<Ref<InitializedApiClient>> = inject('cmsApiClient');

    const { dispatchPageGTMEvents } = useTrackPage(root);
    const { openModal } = useModal();
    const {
      cartId,
      cartItems,
      totals,
      currency,
      isBopisSupported,
      totalItems,
      customerFacingFlashes,
      outOfStockProducts,
    } = storeToRefs(useCartStore());

    /**
     * hasCartId - determines cart existence.
     * false: unkown state
     * null: no cart in localstorage
     * string: cart exists, fetching data in session plugin on ssr
     */
    const hasCartId = ref<false | string>(false);
    const dynamicSlots = ref({});
    provide('dynamicSlots', {
      contextKey,
      dynamicSlots,
    });
    const methodChangedNotificationDisplayed = computed(() => {
      if (!isBopisSupported.value) {
        return false;
      }
      const unavailableItem = cartItems.value.find((item) => {
        const shippingMethod = item.shippingOptions.find(
          ({ shippingMethod, storeInfo }) =>
            isPickupOrSts(shippingMethod.code) && storeInfo
        );
        return shippingMethod && !shippingMethod.available;
      });
      return !!unavailableItem;
    });

    const productsSavedForLater = computed(() => {
      let products = savedForLaterProducts.value;
      return products.map((product) => ({
        ...product,
        image:
          product.productImageURL ||
          root.$mediaUrlGenerator({
            colorName: product.variants.find(({ code }) => code === 'color')
              ?.value,
            pid: product.masterId,
            productName: product.name,
          }),
      }));
    });

    const areProductsInCart = computed(() => cartItems.value.length);

    const isAnythingInCart = computed(() =>
      [
        !!cartItems.value.length,
        !!productsSavedForLater.value.length,
        !!outOfStockProducts.value.length,
      ].some(Boolean)
    );

    const isCartReady = computed(() => {
      return hasCartId.value === null || !!cartId.value;
    });

    const unwatch = watch(isCartReady, (val) => {
      if (val) {
        hideSpinner();
        unwatch();
      }
    });

    onMounted(async () => {
      hasCartId.value = localStorage.getItem('cartId');

      // Show spinner on SSR, when cartId is not populated yet
      if (!cartId.value) {
        showSpinner();
      }

      useCartStore().clearFlashes();
      cmsRefStore.$patch({ pageTypeName });
      if (isBopisEnabled) {
        await updateCartForBopis();
      } else {
        await keepCartAlive();
      }

      // Get products saved for later for normal customers (employees can't save products for later)
      if (!employeeConnected.value) {
        getSaveForLaterItems();
      }

      await nextTick();
      const content = await fetchPageContent(
        null,
        context,
        cmsRefStore.cmsSiteConfiguration,
        cmsApiClient.value.value,
        null,
        getPathWithoutLocalization(`${root.$route.path}-new`) // TODO: GLOBAL15-56318 switch back to unaugmented path after permanent switch to new experience
      );
      dynamicSlots.value = extractDynamicSlots(
        content,
        {
          siteConfiguration: cmsRefStore.cmsSiteConfiguration,
          cmsBaseUri: cmsRefStore.baseUri,
          requirements: cmsRefStore.requirements,
          context,
          pageTypeName,
        },
        cmsRefStore.errors
      );

      useMonetate(root, contextKey).getExperienceDecision();

      isClient &&
        dispatchPageGTMEvents({
          contextKey: Context.PageContent,
          openModal,
          pageTypeName: { value: pageTypeName },
          trackAfterSSR: { value: true },
          useUserData,
          hookLoadPageDataExtendOverrideAttibutes: (overrideAttributes) => {
            return {
              ...overrideAttributes,
              htmlTitle: `${root.$t('cart.title')} | ${root.$env.WEBSITE_NAME}`,
            };
          },
        });
    });

    return {
      theme,
      CheckoutContext,
      isCartReady,
      hasCartId,
      areProductsInCart,
      isAnythingInCart,
      methodChangedNotificationDisplayed,
      productsSavedForLater,
      totals,
      currency,
      contextKey,
      dynamicSlots,
      totalItems,
      customerFacingFlashes,
      outOfStockProducts,
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
    };
  },
  head() {
    return {
      title: `${this.$t('cart.title')} | ${this.$env.WEBSITE_NAME}`,
    };
  },
});
