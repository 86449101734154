





















































































































































import {
  defineComponent,
  inject,
  nextTick,
  ref,
  Ref,
} from '@nuxtjs/composition-api';
import { CheckoutContext, Context } from '@vf/api-contract';

import { storeToRefs } from 'pinia';
import { onMounted, provide, onBeforeUnmount } from '@vue/composition-api';
import { ROUTES, useI18n, useRouting, useGtm } from '@vf/composables';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

import Placement from '@/components/smart/grid/Placement.vue';
import OrderConfirmation from '@/components/static/checkout/success/OrderConfirmation.vue';
import PickupInformation from '@/components/static/checkout/success/PickupInformation.vue';
import GiftOptionSummary from '@/components/static/cart/GiftOptionSummary.vue';
import OrderSummarySidebar from '@/components/static/OrderSummarySidebar.vue';
import InformationalSidebar from '@/components/static/InformationalSidebar.vue';
import SuccessReview from '@/components/static/checkout/review/success/SuccessReview.vue';

import { pluralize } from '@/helpers/pluralize';
import { replaceAll } from '@/helpers/replaceAll';
import useRootInstance from '@/shared/useRootInstance';
import { isPickup } from '@vf/shared/src/utils/helpers';
import { fetchPageContent } from '@vf/composables/src/useCms/dataFetcher/pages';
import { extractDynamicSlots } from '@vf/composables/src/useCms/utils/extractDynamicSlots';
import {
  InitializedApiClient,
  PageTypeName,
} from '@vf/composables/src/useCms/types';
import { ComposableContext } from '@vf/composables/src/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CheckoutSuccessStatic',
  components: {
    SuccessReview,
    PickupInformation,
    OrderConfirmation,
    GiftOptionSummary,
    OrderSummarySidebar,
    InformationalSidebar,
    Placement,
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
  },
  layout: 'defaultStatic',
  transition: 'fade',
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const {
      order,
      orderShipmentsSortedByDeliveryAndShipmentId,
      orderPickups,
    } = storeToRefs(useCheckoutStore());
    const { isCheckoutSuccessRedesignEnabled, enable3ds } = storeToRefs(
      useFeatureFlagsStore()
    );
    provide(
      'isCheckoutSuccessRedesignEnabled',
      isCheckoutSuccessRedesignEnabled
    );

    const orderShipmentsWithItems = ref(null);
    const pageTypeName = PageTypeName.ORDER_CONFIRMATION;
    const contextKey = Context.PageContent;
    const context: ComposableContext = {
      instance: root,
      contextKey,
    };
    const { getPathWithoutLocalization } = useRouting(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);
    const cmsApiClient: Ref<Ref<InitializedApiClient>> = inject('cmsApiClient');
    const dynamicSlots = ref({});

    const { dispatchEvent, dispatchErrorEvent } = useGtm(root);

    onMounted(async () => {
      if (!order.value) {
        return root.$router.push(localePath(ROUTES.HOME()));
      }
      orderShipmentsWithItems.value = orderShipmentsSortedByDeliveryAndShipmentId.value.map(
        ({ shippingMethod, ...shipment }) => {
          let estimatedArrival: string = replaceAll(
            `${root.$t('orderSuccess.estimatedArrival')}`,
            {
              deliveryTime: `${root.$getEDDLabel(
                root,
                shippingMethod.deliveryTime
              )}`,
            }
          );
          if (isPickup(shippingMethod.code)) {
            estimatedArrival = `${root.$t('orderSuccess.pickupReady')}`;
          }

          const items = shipment.items.map((shipmentItem) => {
            // get shipment item sku and pair with order item
            return order.value.items.find(
              (orderItem) =>
                orderItem.sku === shipmentItem.sku &&
                orderItem.shipmentId === shipment.shipmentId
            );
          });

          const count = items.reduce((acc, item) => acc + item.qty, 0);

          return {
            ...shipment,
            estimatedArrival,
            itemsCount: replaceAll(
              `${root.$t(pluralize('orderSuccess.itemCount', count))}`,
              { count }
            ),
            items: items.map((product) => {
              return {
                ...product,
                productPromotions: product.productPromotions?.map(
                  ({ calloutMsg }) => calloutMsg
                ),
              };
            }),
          };
        }
      );

      await nextTick();
      cmsRefStore.$patch({ pageTypeName });
      const content = await fetchPageContent(
        null,
        context,
        cmsRefStore.cmsSiteConfiguration,
        cmsApiClient.value.value,
        null,
        getPathWithoutLocalization(root.$route.path)
      );
      dynamicSlots.value = extractDynamicSlots(
        content,
        {
          siteConfiguration: cmsRefStore.cmsSiteConfiguration,
          cmsBaseUri: cmsRefStore.baseUri,
          requirements: cmsRefStore.requirements,
          context,
          pageTypeName,
        },
        cmsRefStore.errors
      );

      const eventName = 'checkoutCompletion';
      try {
        dispatchEvent({ eventName });
      } catch (err) {
        dispatchErrorEvent(eventName, err);
        root.$log.error(
          `[@theme/pages/index::dispatchOrderStatusEvent] [GTM] ${eventName} event was not fired.`,
          err
        );
      }
    });

    const printReceipt = () => window.print();

    const bounceBackVouchers = order.value?.bounceBackVouchers || [];

    if (enable3ds.value) {
      onBeforeUnmount(() => {
        // We need to reset order when we leave the page
        // to cover edge case when user bought something
        // and want to create other order with different card
        order.value = null;
      });
    }

    return {
      CheckoutContext,
      order,
      printReceipt,
      orderShipmentsWithItems,
      orderPickups,
      dynamicSlots,
      contextKey,
      bounceBackVouchers,
    };
  },
});
