












































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  Ref,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { CardType, CheckoutContext, Context } from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useCardStore,
  useCart,
  useCheckout,
  useGtm,
  useI18n,
  useNotification,
  usePaymentStore,
  useRequestTracker,
  useRouting,
  useSignInToStore,
  useTrackPage,
} from '@vf/composables';
import { useOrders } from '@vf/composables/src/useCheckout/composables/useOrders';
import { useUserData } from '../../components/cms/cmsUtils';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

import Placement from '@/components/smart/grid/Placement.vue';
import CheckoutStep from '@/components/static/checkout/CheckoutStep.vue';
import ShippingStepComponents from '@/components/static/checkout/ShippingStepComponents.vue';
import PaymentStepComponents from '@/components/static/checkout/PaymentStepComponents.vue';
import OrderSummaryProducts from '@/components/static/OrderSummaryProducts.vue';
import OrderSummarySidebar from '@/components/static/OrderSummarySidebar.vue';
import ShippingReview from '@/components/static/checkout/review/ShippingReview.vue';
import CheckoutPaymentReview from '@/components/static/checkout/review/checkout/CheckoutPaymentReview.vue';
import PromoCodeSidebar from '@/components/static/PromoCodeSidebar.vue';
import PlaceOrderButton from '@/components/static/checkout/PlaceOrderButton.vue';
import DiscountNotification from '@/components/static/DiscountNotification.vue';

import useLoader from '@/shared/useLoader';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { fetchPageContent } from '@vf/composables/src/useCms/dataFetcher/pages';
import { extractDynamicSlots } from '@vf/composables/src/useCms/utils/extractDynamicSlots';
import {
  InitializedApiClient,
  PageTypeName,
} from '@vf/composables/src/useCms/types';
import { ComposableContext } from '@vf/composables/src/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { CheckoutStepNumber } from '@vf/composables/src/types/gtm';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import uniqBy from '@vf/composables/src/utils/uniqBy';
import { useOrderStore } from '@vf/composables/src/store/order';
import CheckoutReward from '@/components/smart/checkout/CheckoutRewardCard/CheckoutRewardCardNew.vue';
import { PaymentMethodCode } from '@vf/api-client';

export default defineComponent({
  name: 'CheckoutStatic',
  components: {
    CheckoutReward,
    Placement,
    Notifications: () => import('@/components/smart/layout/Notifications.vue'),
    CheckoutStep,
    ShippingStepComponents,
    PaymentStepComponents,
    ShippingReview,
    CheckoutPaymentReview,
    PromoCodeSidebar,
    OrderSummaryProducts,
    OrderSummarySidebar,
    PlaceOrderButton,
    DiscountNotification,
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    CartNotifications: () =>
      import('@/components/static/CartNotifications.vue'),
  },
  layout: 'checkoutStatic',
  transition: 'fade',
  setup() {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const pageTypeName = PageTypeName.CHECKOUT;

    const {
      clearGuestCart,
      getShippingMethods,
      isCartMergeNotificationVisible,
      loadCart,
      prepareOrder,
    } = useCart(root);
    const { triggerPlaceOrder } = useOrders(root);
    const { basicInformation, getBasicInformation } = useAccount(root);
    const { employeeConnected } = useSignInToStore(root);
    const { localePath } = useI18n(root);
    const { dispatchEvent, dispatchEventAfter } = useGtm(root);
    const { dispatchPageGTMEvents } = useTrackPage(root);
    const {
      isRequestOngoing,
      getOngoingRequest,
      onAllDone,
    } = useRequestTracker(root);
    const { openModal, setNotificationVisibility } = useModal();

    const {
      isCheckoutRedesignEnabled,
      checkoutSkipReviewStep,
      checkoutRegisteredUserSingleStep,
      enable3ds,
    } = useFeatureFlagsStore();

    const { getPaymentMethods } = useCheckout(root);

    const userStore = useUserStore(root);
    const checkoutStore = useCheckoutStore();
    const cartStore = useCartStore();
    const orderStore = useOrderStore();
    const cardStore = useCardStore();
    const paymentStore = usePaymentStore(root);
    const {
      cartItems,
      customerFacingFlashes,
      outOfStockProducts,
    } = storeToRefs(cartStore);
    const { currentStep } = storeToRefs(checkoutStore);

    const contextKey = Context.PageContent;
    const context: ComposableContext = {
      instance: root,
      contextKey,
    };
    const { getPathWithoutLocalization } = useRouting(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);
    const cmsApiClient: Ref<Ref<InitializedApiClient>> = inject('cmsApiClient');
    const dynamicSlots = ref({});
    provide('dynamicSlots', {
      contextKey,
      dynamicSlots,
    });

    const payment = ref(null);
    const shippingStepRef = ref();

    const isPlaceOrderInProgress = ref(false);

    const isLoggedIn = computed(() => {
      return userStore.loggedIn || employeeConnected.value;
    });

    const dispatchCheckoutStepGtmEvent = (step: CheckoutStepNumber) => {
      dispatchEvent({
        eventName: 'checkout',
        overrideAttributes: { step },
      });
    };

    const editCart = () => {
      root.$router.push({ path: localePath(ROUTES.CART()) });
    };

    const setShippingStep = (skipGtmEvent = false) => {
      checkoutStore.setCurrentStep(CheckoutContext.Shipping);
      !skipGtmEvent &&
        dispatchCheckoutStepGtmEvent(CheckoutStepNumber.SHIPPING);
    };

    const setPaymentStep = async (init = true) => {
      showSpinner();
      useCartStore().clearFlashes();
      if (init) await prepareOrder({ refetchCart: false });
      // as prepareOrder may result in changes in eligible shipping methods (background checks and updates on OMS level),
      // we need to fetch them again as it matters for ApplePay, for example
      getShippingMethods('me');
      checkoutStore.setCurrentStep(CheckoutContext.Payment);
      dispatchCheckoutStepGtmEvent(CheckoutStepNumber.BILLING);
      hideSpinner();
    };

    const placeOrder = () => {
      showSpinner();
      isPlaceOrderInProgress.value = true;
      triggerPlaceOrder().finally(() => {
        /*
         * Hide spinner and enable Place Order button as this call
         * may result in redirect to Order Confirmation or showing error message
         */
        hideSpinner();
        isPlaceOrderInProgress.value = false;
        if (!enable3ds || !orderStore.challenge3ds) return;
        paymentStore.service
          .createFromAction(JSON.parse(orderStore.challenge3ds), {
            challengeWindowSize: root.$viewport.breakpoint.smDown ? '01' : '02',
          })
          .mount('#cc-3ds2');
      });
    };

    const setReviewStep = async (skipReviewStep?: boolean) => {
      showSpinner();
      useCartStore().clearFlashes();
      await prepareOrder({ refetchCart: false });

      if (skipReviewStep) {
        await placeOrder();
      } else {
        checkoutStore.setCurrentStep(CheckoutContext.Review);
        dispatchCheckoutStepGtmEvent(CheckoutStepNumber.REVIEW);
        hideSpinner();
      }
    };

    const fetchCartData = async () => {
      useCartStore().clearFlashes();

      if (isRequestOngoing('useCart-loadCart'))
        await getOngoingRequest('useCart-loadCart');
      else
        await loadCart({
          isBackgroundRequest: false,
          isTemporary: false,
          inventorySupplyCheck: true,
        });
    };

    const validatePaymentMethodReady = async () => {
      if (
        !orderStore.paymentMethod ||
        (orderStore.paymentMethod === PaymentMethodCode.CREDIT_CARD &&
          !cardStore.card?.paymentInstrumentId)
      ) {
        await getPaymentMethods();

        orderStore.paymentMethod = PaymentMethodCode.CREDIT_CARD;
        const selectedCard =
          cartStore.availableCreditCards.find((card) => card.card.main) ||
          cartStore.availableCreditCards[0];

        if (selectedCard) {
          const { address } = selectedCard;
          cardStore.card = {
            ...selectedCard.card,
            cardType: selectedCard.card.cardType as CardType,
            address,
          };
          if (address) cartStore.billingAddress = address;
        }
      }

      return (
        orderStore.paymentMethod === PaymentMethodCode.CREDIT_CARD &&
        cardStore.card?.paymentInstrumentId &&
        cardStore.card?.paymentInstrumentId !== 'new'
      );
    };

    const attemptCheckoutRegisteredUserSingleStep = async () => {
      if (!isLoggedIn.value) {
        return;
      }

      await nextTick();
      showSpinner();

      if (orderStore.readyToPlaceOrder) {
        setReviewStep();
        return;
      }

      const [isShippingMethodReady, isPaymentStepReady] = await Promise.all([
        shippingStepRef.value.isShippingStepReady(),
        validatePaymentMethodReady(),
      ]);

      if (!isShippingMethodReady || !isPaymentStepReady) {
        hideSpinner();
      } else {
        setReviewStep();
      }
    };

    const fetchDynamicSlots = async () => {
      await nextTick();
      const content = await fetchPageContent(
        null,
        context,
        cmsRefStore.cmsSiteConfiguration,
        cmsApiClient.value.value,
        null,
        getPathWithoutLocalization(root.$route.path)
      );
      dynamicSlots.value = extractDynamicSlots(
        content,
        {
          siteConfiguration: cmsRefStore.cmsSiteConfiguration,
          cmsBaseUri: cmsRefStore.baseUri,
          requirements: cmsRefStore.requirements,
          context,
          pageTypeName,
        },
        cmsRefStore.errors
      );
    };

    const goHome = () => {
      hideSpinner();
      root.$router.push(localePath(ROUTES.HOME()));
    };

    const needsBasicInformation = computed(() =>
      userStore.loggedIn ? !basicInformation.value.email : false
    );

    const shippingInfoReady = computed(() =>
      needsBasicInformation.value ? !!basicInformation.value.email : true
    );

    onMounted(async () => {
      if (!localStorage.getItem('cartId')) return goHome();

      showSpinner();
      cmsRefStore.$patch({ pageTypeName });

      if (
        checkoutRegisteredUserSingleStep &&
        isLoggedIn.value &&
        orderStore.readyToPlaceOrder
      ) {
        setReviewStep();
      } else {
        setShippingStep(true);
      }

      if (needsBasicInformation.value) getBasicInformation();

      await fetchCartData();

      if (!cartItems.value.length && !outOfStockProducts.value.length) {
        return goHome();
      }

      if (shippingInfoReady.value) {
        hideSpinner();

        if (checkoutRegisteredUserSingleStep) {
          attemptCheckoutRegisteredUserSingleStep();
        }
      } else {
        const removeShippingInfoReadyWatch = watch(shippingInfoReady, () => {
          hideSpinner();

          if (checkoutRegisteredUserSingleStep) {
            attemptCheckoutRegisteredUserSingleStep();
          }

          removeShippingInfoReadyWatch();
        });
      }

      await fetchDynamicSlots();

      dispatchPageGTMEvents({
        contextKey: Context.PageContent,
        openModal,
        pageTypeName: { value: pageTypeName },
        trackAfterSSR: { value: true },
        useUserData,
        hookLoadPageDataExtendOverrideAttibutes: (overrideAttributes) => {
          return {
            ...overrideAttributes,
            htmlTitle: `${root.$t('checkout.title')} | ${
              root.$env.WEBSITE_NAME
            }`,
          };
        },
      });

      onAllDone(() => {
        dispatchEventAfter('virtualPageView', () => {
          dispatchCheckoutStepGtmEvent(CheckoutStepNumber.SHIPPING);
        });
      });
    });

    const unwatchIsLoggedIn = watch(isLoggedIn, (hasLoggedIn) => {
      if (hasLoggedIn) {
        getBasicInformation();
        if (isCartMergeNotificationVisible()) {
          // Set visibility for notification inside modal to false
          setNotificationVisibility(false);
          useNotification(root).addNotification({
            message: root.$t('checkoutLogin.cartMergedNotification') as string,
            type: 'success',
          });
          clearGuestCart();
        }
        unwatchIsLoggedIn();
      }
    });

    const cartItemsWithFlashes = computed(() => {
      const productIds = customerFacingFlashes.value
        .map((item) => item.details?.productId || item.details?.sku)
        .filter((productId) => !!productId);
      const items = outOfStockProducts.value
        .concat(cartItems.value)
        .filter((item) => productIds.includes(item.productId));
      return uniqBy(items, 'productId');
    });

    onBeforeUnmount(() => {
      // reset currentStep when use leaves checkout page
      useCartStore().clearFlashes();
      checkoutStore.setCurrentStep(CheckoutContext.Shipping);
      isPlaceOrderInProgress.value = false;
      hideSpinner();
    });

    // TODO: GLOBAL15-56318 clean up
    onBeforeMount(() => {
      !isCheckoutRedesignEnabled &&
        root.$router.push(ROUTES.CHECKOUT_SHIPPING());
    });

    watch(currentStep, (step) => {
      orderStore.readyToPlaceOrder = step === CheckoutContext.Review;

      if (step === CheckoutContext.Payment) {
        nextTick(() => {
          const headerHeight = (document.querySelector(
            '.vf-checkout-header'
          ) as HTMLElement).offsetHeight;

          scrollTo({
            top: payment.value.$el.offsetTop - headerHeight,
          });
        });
      }
    });

    provide('isCheckoutRedesignEnabled', isCheckoutRedesignEnabled); // TODO: GLOBAL15-56318 clean up

    return {
      threeDSModalOpen: computed(() => !!orderStore.challenge3ds),
      shippingStepRef,
      payment,
      CheckoutContext,
      isLoggedIn,
      editCart,
      cartItems,
      currentStep,
      setShippingStep,
      setPaymentStep,
      setReviewStep,
      placeOrder,
      isPlaceOrderInProgress,
      shippingInfoReady,
      contextKey,
      dynamicSlots,
      customerFacingFlashes,
      cartItemsWithFlashes,
      checkoutSkipReviewStep,
      checkoutRegisteredUserSingleStep,
    };
  },
  head() {
    return {
      title: `${this.$t('checkout.title')} | ${this.$env.WEBSITE_NAME}`,
    };
  },
});
