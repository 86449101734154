












































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
  FavoritesTranslations,
  FavoriteProductObject,
  FavoriteItemNotification,
} from '@vf/api-contract';
export default defineComponent({
  name: 'VfFavoritesProducts',
  props: {
    translations: {
      type: Object as PropType<FavoritesTranslations>,
      default: () => ({}),
    },
    products: {
      type: Array as PropType<FavoriteProductObject[]>,
      default: () => [],
    },
    viewType: {
      type: String as PropType<string>,
      default: 'grid',
    },
    itemNotification: {
      type: Object as PropType<FavoriteItemNotification>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const addToCartLabel = computed(() => props.translations.addToCartLabel);
    const itemNotificationLabel = computed(() =>
      props.itemNotification.type === 'addToCart'
        ? props.translations.addedToCartText
        : props.translations.removedItemText
    );

    const tiles = computed(() => {
      const result = [];
      props.products.forEach((product) =>
        result.push({ ...product, isNotification: false })
      );

      if (props.itemNotification.display) {
        result.splice(props.itemNotification.itemIndex, 0, {
          isNotification: true,
        });
      }

      return result;
    });

    const triggerRemoveItem = (
      item: FavoriteProductObject,
      itemIndex: number
    ) => {
      emit('remove-item', { item: item, itemIndex: itemIndex });
    };

    const triggerAddToCart = (itemId: string, itemIndex: number) => {
      emit('move-to-cart', { itemId: itemId, itemIndex: itemIndex });
    };

    return {
      addToCartLabel,
      tiles,
      itemNotificationLabel,
      triggerAddToCart,
      triggerRemoveItem,
    };
  },
});
